/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { Card } from '@components/Card';

import {
  getUsersAccessList,
  storeUserAccess,
} from '@models/users/apiRequests/userRequests';
import {
  IAccountUser,
  IUserAccessItem,
  IUserRight,
} from '@models/users/utils/userTypes';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { Toggle } from '@components/atomic/inputs/controls/Toggle';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { ModalDeleteUser } from '@models/users/components/ModalDeleteUser';
import { ColorCube } from '@components/atomic/ColorCube';
import { CheckIcon } from '@assets/images/svgComponents';
import { blueOpacity, blueOpx } from '@assets/color';
import { AuthContext } from '@context/authContext';
import { verifyPermission } from '@models/contracts/utils/contractHelper';
import { CREER } from '../utils/userConstants';

interface ICardUserRightsProps {
  data: IAccountUser | undefined;
  isAdmin: boolean;
  refetch: CallableFunction;
}

function CardUserRights({ data, isAdmin, refetch }: ICardUserRightsProps) {
  const { t } = useTranslation();
  const { globalEnum, userView } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [userRights, setUserRights] = useState<IUserRight>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [selectedAccessList, setSelectedAccessList] = useState<
    IUserAccessItem[]
  >([]);
  const [selectedProfileType, setSelectedProfileType] = useState<number>(1);

  const userIsMe = useMemo(
    () => !!(user && data && user.id === data.id),
    [user, data]
  );

  const getUserRights = async (profile_type?: number, isEdit?: boolean) => {
    setLoading(true);

    const resAccess = await getUsersAccessList(
      profile_type,
      userView?.entity_id || 0,
      data?.id || 0
    );

    if (resAccess) {
      setUserRights(resAccess);
      setSelectedAccessList(resAccess.access_list);
      setSelectedProfileType(resAccess.profile_type);
    }
    if (!isEdit) {
      setIsEditMode(false);
    }
    setLoading(false);
  };

  const saveUserAccess = async () => {
    setLoading(true);
    const resStoreAccess = await storeUserAccess(
      userRights?.entity_id || 0,
      selectedAccessList
        .filter((accessItem: IUserAccessItem) => accessItem.toggle === true)
        .map((itemAccess: IUserAccessItem) => itemAccess.access),
      userRights?.user_created_details.id || 0,
      userRights?.profile_type || 1
    );

    if (resStoreAccess) {
      refetch();
    } else {
      setIsEditMode(false);
      setLoading(false);
      refetch();
    }
  };

  const handleSelection = (value: any) => {
    const item = Object.keys(globalEnum.profile_type).find(
      (key: string) =>
        (globalEnum.profile_type as any)[key].toLowerCase() ===
        value.toLowerCase()
    );
    setSelectedProfileType(Number(item));
    getUserRights(Number(item), true);
  };

  const isEmpty = useMemo(() => {
    return !selectedAccessList.some((item) => item.toggle);
  }, [selectedAccessList]);

  const actionButtons = useMemo(() => {
    if (
      isAdmin &&
      userRights &&
      user &&
      verifyPermission(user, [CREER.COMPTE])
    ) {
      return isEditMode ? (
        <>
          <ButtonOpx
            onClick={() => {
              setIsEditMode(false);
              refetch();
            }}
            label={t('buttons.cancel')}
            type="secondary"
            small
            addClass="!border-transparent"
          />
          <ButtonOpx
            onClick={saveUserAccess}
            label={t('buttons.save')}
            type="primary"
            small
            addClass="!border-transparent"
            disabled={Boolean(isEmpty)}
          />
        </>
      ) : (
        <ButtonOpx
          onClick={() => setIsEditMode(true)}
          label={t('buttons.update')}
          type="tierciary"
          addClass="!border-transparent"
          small
        />
      );
    }
    return undefined;
  }, [
    isAdmin,
    isEditMode,
    userRights,
    selectedAccessList,
    selectedProfileType,
    t,
  ]);

  const toggleAllisOn = useMemo(() => {
    return !(
      selectedAccessList.filter(
        (accessItem: IUserAccessItem) => !accessItem.toggle
      ).length > 0
    );
  }, [selectedAccessList]);

  const user_str_profile = useMemo(() => {
    const resProfile =
      globalEnum && globalEnum.profile_type && userRights
        ? globalEnum.profile_type[userRights.profile_type || 1]
        : '';
    return `${resProfile.charAt(0).toUpperCase()}${resProfile
      .slice(1)
      .toLowerCase()}`;
  }, [userRights, globalEnum]);

  useEffect(() => {
    getUserRights();
  }, [userView, data]);

  return (
    <Card
      title={t('my_account.access_management')}
      actionButtons={actionButtons}
      addClass="mt-6"
      underlineHeader={false}
    >
      <>
        {loading ? (
          [...Array(3)].map(() => (
            <LoaderSkeleton key={v4()} height="2.1875rem" addClass="mb-2" />
          ))
        ) : (
          <>
            {userRights ? (
              <div className="border p-4 rounded-default">
                <div className="flex items-center justify-between py-4">
                  {isEditMode ? (
                    <InputSelect
                      dataArrayString={Object.values(
                        globalEnum.profile_type
                      ).map(
                        (role: string) =>
                          role.charAt(0).toUpperCase() +
                          role.slice(1).toLowerCase()
                      )}
                      onSelect={handleSelection}
                      placeholder="Choisir une option"
                      valueInput={user_str_profile}
                      addClass="w-[300px]"
                      disabled={userIsMe}
                    />
                  ) : (
                    <p className="font-medium text-[1.25rem] leading-[2.25rem] mb-4 px-4">
                      {user_str_profile}
                    </p>
                  )}

                  {isAdmin ? (
                    <Toggle
                      tooltip={
                        !isEditMode
                          ? String(t('my_account.update_indicator') || '')
                          : ''
                      }
                      label="toggle all"
                      isToggleOn={toggleAllisOn}
                      onToggle={(isOn: boolean) => {
                        const allAccessOnOff = userRights?.access_list.map(
                          (accessItem: IUserAccessItem) => {
                            if (accessItem.access === 5 && userIsMe) {
                              return {
                                access: accessItem.access,
                                toggle: true,
                              };
                            }
                            return {
                              access: accessItem.access,
                              toggle: isOn,
                            };
                          }
                        );
                        setSelectedAccessList(allAccessOnOff || []);
                      }}
                      disabled={!isEditMode}
                      addClass={`${
                        !isEditMode ? 'hover:cursor-not-allowed' : ''
                      }`}
                    />
                  ) : toggleAllisOn ? (
                    <ColorCube
                      numberOrIcon={<CheckIcon />}
                      color={blueOpx}
                      size="1.5rem"
                      backgroundColor={blueOpacity}
                    />
                  ) : null}
                </div>
                {userRights?.access_list.map((accessItem: IUserAccessItem) => {
                  const isToggleOn = Boolean(
                    selectedAccessList.find(
                      (itemAccess: IUserAccessItem) =>
                        itemAccess.access === accessItem.access
                    )?.toggle
                  );

                  const toggleDisabled =
                    !isEditMode || (accessItem.access === 5 && userIsMe);

                  const hideLine = !isAdmin && !isToggleOn;
                  return hideLine ? null : (
                    <div
                      role="button"
                      key={v4()}
                      className={`flex items-center justify-between px-4 py-2 border rounded-default mb-2 cursor-pointer ${
                        !isEditMode ? 'hover:cursor-not-allowed' : ''
                      }`}
                      title={
                        !isEditMode
                          ? String(t('my_account.update_indicator') || '')
                          : ''
                      }
                      onClick={() => {
                        if (isEditMode) {
                          const selectedAccessIndex =
                            selectedAccessList.findIndex(
                              (itemAccess: IUserAccessItem) =>
                                itemAccess.access === accessItem.access
                            );
                          const tmpAccesses = selectedAccessList;
                          tmpAccesses[selectedAccessIndex] = {
                            ...tmpAccesses[selectedAccessIndex],
                            toggle: !isToggleOn,
                          };

                          if (userIsMe) {
                            setSelectedAccessList((prevState) => {
                              return prevState.map((elt) =>
                                elt.access === 5
                                  ? { ...elt, toggle: true }
                                  : elt
                              );
                            });
                          } else {
                            setSelectedAccessList([...tmpAccesses]);
                          }
                        }
                      }}
                    >
                      <p>{globalEnum.access_type[accessItem.access]}</p>
                      {isAdmin ? (
                        <Toggle
                          label={v4()}
                          tooltip=""
                          isToggleOn={isToggleOn}
                          onToggle={() => null}
                          disabled={toggleDisabled}
                          addClass={toggleDisabled ? 'cursor-not-allowed' : ''}
                        />
                      ) : isToggleOn ? (
                        <ColorCube
                          numberOrIcon={<CheckIcon />}
                          color={blueOpx}
                          size="1.5rem"
                          backgroundColor={blueOpacity}
                        />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="list_noElements flex items-center justify-center h-36 text-danger">
                {t('list.no_result')}
              </div>
            )}
            {isAdmin && !userIsMe && (
              <>
                <ButtonOpx
                  onClick={() => setShowModalDelete(!showModalDelete)}
                  label={t('my_account.delete_user')}
                  type="secondary"
                  addClass="flex border-transparent mt-8 ml-auto"
                  small
                />
                {showModalDelete && data && (
                  <ModalDeleteUser
                    setShowModal={setShowModalDelete}
                    selectedUser={data}
                    refetch={refetch}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    </Card>
  );
}

export { CardUserRights };
