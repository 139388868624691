/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm, FormProvider } from 'react-hook-form';
import { v4 } from 'uuid'; //

import { GlobalContext } from '@context/globalContext';

import { Card } from '@components/Card';
import { Tag } from '@components/atomic/Tag';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputInformationRead } from '@components/informations/InputInformationRead';
import { InputInformationEdit } from '@components/informations/InputInformationEdit';
import { FormInfosUser } from '@models/users/components/FormInfosUser';
import { FormSignataire } from '@models/users/components/FormSignataire';

import { ROLES } from '@utils/roles';
import { IInfosLine, IStatus } from 'types/globalTypes';
import { lightBlue, red } from '@assets/color';
import { AuthContext } from '@context/authContext';
import { GERER } from '@models/contracts/utils/contractConstants';
import { verifyPermission } from '@models/contracts/utils/contractHelper';
import { CREER } from '@models/users/utils/userConstants';
import { toggleUserStatus } from '@models/users/apiRequests/userRequests';

interface ICardInformationProps {
  isAccountUser?: boolean;
  title: string;
  data: {
    id?: number;
    status: boolean | string | undefined | IStatus;
    rows: IInfosLine[][];
    additionalFunction?: Record<string, CallableFunction>;
  };
  loading: boolean;
  onEdit?: CallableFunction;
  layout?: 'signataire' | 'infos-user' | 'oneColumn';
  underlineHeader?: boolean;
  hideTitles?: boolean;
  customLabelHeaderButton?: string;
  onClickHeaderButton?: CallableFunction;
  customEditModeButton?: JSX.Element;
  dataTestId?: string;
  refresh?: () => Promise<void>;
  onClickAddSignatory?: (e: React.MouseEvent) => void;
  isLoadingUpdate?: boolean;
  onChange?: (value: string, name: string) => void;
  addClass?: string;
}

function CardInformations({
  title,
  data,
  loading,
  onEdit,
  layout,
  underlineHeader,
  hideTitles,
  customLabelHeaderButton,
  onClickHeaderButton,
  customEditModeButton,
  dataTestId,
  isAccountUser,
  refresh,
  onClickAddSignatory,
  isLoadingUpdate,
  addClass,
}: ICardInformationProps) {
  const { t } = useTranslation();
  const { roleUser } = useContext(GlobalContext);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const methods = useForm();
  const [isToggle, setIsToggle] = useState<boolean>(false);

  const { handleSubmit, setValue, getValues } = methods;

  const setInitialFormValues = () => {
    data.rows.forEach((row: IInfosLine[]) => {
      row
        .filter((field: IInfosLine) => field.isEditable)
        .forEach((editableField: IInfosLine) => {
          setValue(editableField.name, editableField.value);
        });
    });
  };
  const onSubmit = async (formData: FieldValues) => {
    if (onEdit) onEdit(formData);
    setIsEditMode(false);
  };

  const toggleStatus = async (formData: FieldValues) => {
    setIsToggle(true);
    if (onEdit) onEdit(formData);
    await toggleUserStatus(data.id, !data.status);

    setIsToggle(false);
  };

  const userIsMe = useMemo(
    () => !!(user && data && user.id === data.id),
    [user, data]
  );

  const actionUserStatus = useMemo(() => {
    if (data.rows[0][1] && data.rows[0][1].value === null) {
      return (
        <div className="text-[1rem]">
          <Tag
            color={red}
            label={t('my_account.not_registered')}
            addClass="mb-4 text-[1rem]"
          />
        </div>
      );
    }

    if (
      !userIsMe &&
      layout === 'infos-user' &&
      isAccountUser &&
      verifyPermission(user, [CREER.COMPTE])
    ) {
      return (
        <ButtonOpx
          onClick={handleSubmit(toggleStatus)}
          label={
            data.status ? t('buttons.disable_user') : t('buttons.activate_user')
          }
          type={data.status ? 'secondary' : 'primary'}
          small
          addClass={data.status ? '' : '!border-transparent'}
          isLoading={isToggle}
        />
      );
    }
    return undefined;
  }, [data, roleUser, isToggle]);

  const actionButtons = useMemo(() => {
    const isCardEditable =
      data.rows.findIndex(
        (row: IInfosLine[]) =>
          row.filter((field: IInfosLine) => field.isEditable).length > 0
      ) !== -1;

    if (customLabelHeaderButton && onClickHeaderButton) {
      return (
        <ButtonOpx
          onClick={() => onClickHeaderButton()}
          label={customLabelHeaderButton}
          type="tierciary"
          small
          addClass="!border-transparent"
        />
      );
    }
    if (
      (isAccountUser ||
        (isCardEditable &&
          title === t('worksites.intern_referent.title') &&
          roleUser !== ROLES.PRODUCTION) ||
        (isCardEditable &&
          title !== t('worksites.intern_referent.title') &&
          user &&
          ((!isAccountUser && verifyPermission(user, [GERER.CONTRACT])) ||
            (isAccountUser && verifyPermission(user, [CREER.COMPTE]))))) &&
      title !== t('worksites.worksite_information')
    ) {
      return isEditMode || isLoadingUpdate ? (
        <div className="flex">
          <ButtonOpx
            onClick={() => {
              setIsEditMode(false);
              setInitialFormValues();
              if (data?.additionalFunction?.callbackOnClose)
                data?.additionalFunction?.callbackOnClose();
            }}
            label={t('buttons.cancel')}
            type="secondary"
            small
            addClass="!border-transparent"
          />
          {customEditModeButton || null}
          <ButtonOpx
            onClick={handleSubmit(onSubmit)}
            label={t('buttons.save')}
            type="primary"
            small
            addClass="!border-transparent"
            isLoading={isLoadingUpdate}
          />
        </div>
      ) : (
        <ButtonOpx
          onClick={() => {
            setIsEditMode(true);
            setInitialFormValues();
          }}
          label={t('buttons.update')}
          type="tierciary"
          addClass="!border-transparent"
          small
        />
      );
    }
    return undefined;
  }, [isEditMode, data, roleUser]);

  const renderInputInformation = (col: IInfosLine) => {
    if (col.component)
      return (
        <div className="flex mt-4">
          <div className="w-[1.75rem] mr-[1rem]" />
          <div>
            <div className="text-textGrey text-[.75rem] leading-[.75rem]">
              {col.title}
            </div>
            <div className="mt-2"> {col.component}</div>
          </div>
        </div>
      );

    return (
      <div className={`${col.addClass || ''}`}>
        {(isEditMode && col.isEditable) || col.type === 'projectedEnd' ? (
          <InputInformationEdit
            data={col}
            value={getValues(col.name)}
            setValue={setValue}
            refresh={refresh}
          />
        ) : (
          <InputInformationRead
            data={col}
            isEditMode={isEditMode}
            loading={loading}
          />
        )}
      </div>
    );
  };

  useEffect(() => setInitialFormValues(), [data]);

  const classRow = (iRow: number) => {
    let classToReturn =
      layout === 'oneColumn'
        ? 'flex flex-col space-y-4'
        : 'grid gap-4 grid-cols-2';

    if (layout === 'signataire') return classToReturn;
    if (iRow < data.rows.length - 1) {
      classToReturn +=
        ' border border-solid border-transparent border-b-borderGrey';
      if (iRow === 0) {
        classToReturn += ' pb-4';
      } else {
        classToReturn += ' py-4';
      }
    }
    if (iRow === data.rows.length - 1 && data.rows.length > 1) {
      classToReturn += ' pt-4';
    }
    return classToReturn;
  };

  const statusLabel = useMemo(() => {
    if (typeof data.status === 'string') {
      return data.status || '';
    }
    return data.status ? 'Actif' : 'Inactif';
  }, [data]);

  const renderRows = useMemo(() => {
    switch (layout) {
      case 'infos-user':
        return (
          <FormInfosUser
            classRow={classRow(0)}
            renderInputInformation={renderInputInformation}
            isEditMode={isEditMode}
            data={data}
            userIsActive={!!data.status}
          />
        );
      case 'signataire':
        return (
          <FormSignataire
            classRow={classRow}
            renderInputInformation={renderInputInformation}
            isEditMode={isEditMode}
            data={data}
            setIsEditMode={setIsEditMode}
            onClickAddSignatory={onClickAddSignatory}
          />
        );
      default:
        return data.rows.map((row: IInfosLine[], iRow: number) => (
          <div key={v4()} className={classRow(iRow)}>
            {row.map((col: IInfosLine) => (
              <div key={v4()}>{!col.empty && renderInputInformation(col)}</div>
            ))}
          </div>
        ));
    }
  }, [layout, isEditMode, data]);

  return (
    <Card
      title={title}
      addClass={`h-[max-content] w-full ${addClass}`}
      actionButtons={actionButtons}
      hideTitles={hideTitles}
      underlineHeader={underlineHeader}
      dataTestId={dataTestId}
      actionUserStatus={actionUserStatus}
    >
      <FormProvider {...methods}>
        <form id="form-card-infos" onSubmit={handleSubmit(onSubmit)}>
          {data.status && layout !== 'infos-user' && (
            <Tag
              color={
                (data.status as IStatus).color
                  ? (data.status as IStatus).color
                  : data.status
                  ? lightBlue
                  : red
              }
              label={(data.status as IStatus).label || statusLabel}
              addClass="mb-4"
            />
          )}
          {renderRows}
        </form>
      </FormProvider>
    </Card>
  );
}

CardInformations.defaultProps = {
  onEdit: undefined,
  layout: undefined,
  underlineHeader: true,
  hideTitles: false,
  customLabelHeaderButton: undefined,
  onClickHeaderButton: undefined,
  customEditModeButton: undefined,
  dataTestId: '',
  isAccountUser: false,
  refresh: undefined,
  onClickAddSignatory: undefined,
  isLoadingUpdate: false,
  onChange: undefined,
  addClass: '',
};

export { CardInformations };
