import { useCallback, useContext, useEffect, useState } from 'react';
import {
  IArrayInput,
  IGraph,
  IOneArrayKey,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { ColorCube } from '@components/atomic/ColorCube';
import { TrashIcon } from '@assets/images/svgComponents';
import { red } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputText } from '@components/atomic/inputs/InputText';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useTranslation } from 'react-i18next';
import ElementCardInArray from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/ElementCardInArray';

interface IOneArrayKeyProps {
  graph: IGraph;
  oneArrayKeyDatas: IOneArrayKey;
  operationIndex: number;
}

function OneArrayKey({
  graph,
  oneArrayKeyDatas,
  operationIndex,
}: IOneArrayKeyProps) {
  const { t } = useTranslation();
  const { updateSimulatorDataOperation, simulatorDataOperations } = useContext(
    WorksiteCreationContext
  );
  const { watch, control, setValue, setError, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}`,
  });

  const [initialisedWithSavedData, setInitialisedWithSavedData] =
    useState<boolean>(false);

  const formValuesOperation = watch(String(operationIndex));
  const formValuesOperationMultipleArray =
    formValuesOperation && formValuesOperation.operation
      ? formValuesOperation.operation[graph.key.split('.')[1]]
      : undefined;

  const checkIfErrorMinMax = (element: IArrayInput, elementKey: string) => {
    const { minValue, maxValue } = element;
    if (minValue || maxValue) {
      const value = watch(elementKey) ? Number(watch(elementKey)) : undefined;
      if (value !== undefined) {
        if (minValue && value < minValue) {
          return t('worksite_creation.simulation.error_min_value', {
            minValue,
          });
        }
        if (maxValue && value > maxValue) {
          return t('worksite_creation.simulation.error_max_value', {
            maxValue,
          });
        }
      }
    }
    return undefined;
  };

  const onChangeForm = (element?: IArrayInput, elementKey?: string) => {
    if (formValuesOperationMultipleArray) {
      if (element && elementKey) {
        const error = checkIfErrorMinMax(element, elementKey);
        if (error) {
          setError(elementKey, { message: error });
        } else {
          clearErrors(elementKey);
        }
      }
      updateSimulatorDataOperation((prevState) =>
        prevState.map((item, index) =>
          index === operationIndex
            ? { ...item, [graph.key]: formValuesOperationMultipleArray }
            : item
        )
      );
    }
  };

  const handleAddElement = useCallback(() => {
    append({});
  }, [append]);

  const handleDeleteElement = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  // Initialisation des champs selon oneArrayKeyDatas.min
  useEffect(() => {
    if (!initialisedWithSavedData && formValuesOperationMultipleArray) {
      const savedData =
        simulatorDataOperations &&
        simulatorDataOperations[operationIndex] &&
        simulatorDataOperations[operationIndex][graph.key] &&
        (simulatorDataOperations[operationIndex][graph.key] as any)[
          oneArrayKeyDatas.key
        ];

      if (savedData && savedData.length > 0 && fields.length === 0) {
        // Initialiser les champs avec les données sauvegardées
        savedData.forEach((item: { [x: string]: any }, index: number) => {
          append({});
          Object.keys(item).forEach((key) => {
            setValue(
              `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}.${index}.${key}`,
              item[key]
            );
          });
        });
      } else if (oneArrayKeyDatas.min && fields.length < oneArrayKeyDatas.min) {
        for (let i = fields.length; i < oneArrayKeyDatas.min; i += 1) {
          append({});
        }
      }
      setInitialisedWithSavedData(true);
      onChangeForm();
    }
  }, [
    oneArrayKeyDatas.min,
    simulatorDataOperations,
    operationIndex,
    graph.key,
    oneArrayKeyDatas.key,
    formValuesOperationMultipleArray,
    watch(String(operationIndex)),
  ]);

  return (
    <div
      key={oneArrayKeyDatas.key}
      className="text-[.875rem] border border-borderGrey rounded-default"
    >
      <p className="p-3 w-full border-b border-borderGrey">
        {oneArrayKeyDatas.title}
      </p>
      <div className="p-3 flex flex-col space-y-2">
        {fields.map((fieldRecord, index) => (
          <>
            {oneArrayKeyDatas.subtitle && (
              <div>{`${oneArrayKeyDatas.subtitle}  ${index + 1}`}</div>
            )}
            <div
              key={fieldRecord.id}
              className={`flex space-x-2 items-center pb-2 ${
                index !== fields.length - 1 ? 'border-b border-borderGrey' : ''
              }`}
            >
              <div
                className={`w-full grid grid-cols-${oneArrayKeyDatas.gridType} gap-2`}
              >
                {oneArrayKeyDatas.elements.map((element) => {
                  const elementKey = `${operationIndex}.${graph.key}.${oneArrayKeyDatas.key}.${index}.${element.key}`;
                  if (element.type === 'card') {
                    return (
                      <ElementCardInArray
                        key={elementKey}
                        element={element}
                        elementKey={elementKey}
                      />
                    );
                  }
                  return (
                    <InputText
                      key={elementKey}
                      id={elementKey}
                      name={elementKey}
                      label={element.label}
                      placeholder={element.placeholder ?? element.label}
                      onChange={() => onChangeForm(element, elementKey)}
                      typeNumber={element.type === 'input-number'}
                      valueInitialInput={element.defaultValue}
                      required
                    />
                  );
                })}
              </div>
              {oneArrayKeyDatas.canBeIncreased &&
                fields.length > (oneArrayKeyDatas.min || 0) && (
                  <ColorCube
                    opacity
                    onClick={() => {
                      handleDeleteElement(index);
                      onChangeForm();
                    }}
                    size="1.5rem"
                    numberOrIcon={<TrashIcon />}
                    color={red}
                  />
                )}
            </div>
          </>
        ))}
        {oneArrayKeyDatas.canBeIncreased &&
          (!oneArrayKeyDatas.max || fields.length < oneArrayKeyDatas.max) && (
            <ButtonOpx
              label={
                oneArrayKeyDatas.elementToAdd
                  ? `+ ${t('buttons.add')} ${oneArrayKeyDatas.elementToAdd}`
                  : `+ ${t('buttons.add_element')}`
              }
              small
              onClick={() => {
                handleAddElement();
                onChangeForm();
              }}
              type="tierciary"
            />
          )}
      </div>
    </div>
  );
}

export default OneArrayKey;
